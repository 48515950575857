@import url(https://fonts.googleapis.com/css2?family=Noto+Serif:wght@400&family=Nunito+Sans:wght@400;500;600;700;800);
body {
  margin: 0;
  font-size: 16px;
}
/* Google places autocomplete container */
.pac-container {
  z-index: 1300;
}

.page-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

